import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Colors, Icon, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { useDebounce } from "usehooks-ts";

import { rollupClient } from "src/api";
import PasswordInput from "src/components/PasswordInput";
import useSearchParam from "src/hooks/useSearchParam";
import appStore from "src/store/AppStore";

import "./Login.scss";

function NewPassword() {
  const [password, setPassword] = useState("");
  const debounceNewPassword = useDebounce(password, 400);
  const [validatingPassword, setValidatingPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState({
    has_digit: true,
    has_lower_case: true,
    has_symbol: true,
    has_upper_case: true,
    missing_characters: 0,
    missing_complexity: 0,
  });
  const [token] = useSearchParam("token");
  const navigate = useNavigate();
  const disabled = !password || !passwordValid || validatingPassword;

  useEffect(() => {
    if (debounceNewPassword) {
      setValidatingPassword(true);
      rollupClient.auth
        .checkPasswordStrength(debounceNewPassword)
        .then(r => {
          setPasswordValid(r.data.valid_password);
          setPasswordValidation(r.data.luds_feedback);
        })
        .finally(() => setValidatingPassword(false));
    } else {
      setPasswordValid(true);
    }
  }, [debounceNewPassword]);

  const handleSetNewPassword = async () => {
    if (!disabled) {
      const result = await appStore.setNewPassword(password, token);
      result && navigate("/");
    }
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <div className="login-form">
          <h1 className="login-form--title">Password recovery</h1>
          <PasswordInput onEnter={handleSetNewPassword} loading={validatingPassword} password={password} onChange={setPassword} />
          <Button loading={appStore.loginLinkPending} onClick={handleSetNewPassword} disabled={disabled} large intent={Intent.PRIMARY} fill>
            Continue
          </Button>
          <div className="login-form--validation" style={{ opacity: password && !passwordValid ? 1 : 0 }}>
            <div>
              <Icon
                color={passwordValidation.missing_characters ? Colors.RED3 : Colors.GREEN4}
                icon={passwordValidation.missing_characters ? "cross" : "tick"}
              />
              Contains at least 10 characters
            </div>
            <div>
              <Icon
                color={passwordValidation.has_symbol ? Colors.GREEN4 : Colors.RED3}
                icon={passwordValidation.has_symbol ? "tick" : "cross"}
              />
              Contains at least 1 special character
            </div>
            <div>
              <Icon
                color={passwordValidation.has_upper_case ? Colors.GREEN4 : Colors.RED3}
                icon={passwordValidation.has_upper_case ? "tick" : "cross"}
              />
              Contains at least 1 uppercase letter
            </div>
            <div>
              <Icon
                color={passwordValidation.has_lower_case ? Colors.GREEN4 : Colors.RED3}
                icon={passwordValidation.has_lower_case ? "tick" : "cross"}
              />
              Contains at least 1 lowercase letter
            </div>
            <div>
              <Icon
                color={passwordValidation.has_digit ? Colors.GREEN4 : Colors.RED3}
                icon={passwordValidation.has_digit ? "tick" : "cross"}
              />
              Contains at least 1 number
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(NewPassword);
