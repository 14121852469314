import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import jwtDecode, { JwtPayload } from "jwt-decode";

import { HttpClient, ParentClient } from "src/api/client";
import { PasswordValidationResult } from "src/models/passwords";

import { OrgData, UserData } from "./types";

export enum UserRole {
  user = "user",
  admin = "admin",
  owner = "owner",
}

export enum UserPermission {
  DeleteWorkspaces = "delete:workspaces",
  InviteUsers = "invite:users",
  PromoteUsers = "promote:users",
  DeleteUsers = "delete:users",
  UpdateOrganization = "write:organization",
  DeleteOrganization = "delete:organization",
}

function getPermissions(role: UserRole): UserPermission[] {
  const permissions = new Array<UserPermission>();
  switch (role) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Intentional fallthrough
    case UserRole.owner:
      permissions.push(UserPermission.DeleteOrganization);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Intentional fallthrough
    case UserRole.admin:
      permissions.push(
        UserPermission.DeleteWorkspaces,
        UserPermission.PromoteUsers,
        UserPermission.DeleteUsers,
        UserPermission.UpdateOrganization
      );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Intentional fallthrough
    case UserRole.user:
      permissions.push(UserPermission.InviteUsers);
      break;
  }

  return permissions;
}

export interface AuthClaims {
  userId: string;
  sessionId?: string;
  orgId?: string;
  slug?: string;
  role?: UserRole;
  permissions?: UserPermission[];
}

export enum TokenError {
  Unknown = "unknown error",
  MissingToken = "missing token",
  InvalidToken = "invalid token",
  IncorrectOrg = "incorrect org",
  None = "",
}

export class Auth extends HttpClient {
  // Tokens within 10 seconds of expiry considered invalid
  private static TokenExpiryBuffer = 10;
  // Docs tokens expire after 30 days, so a buffer of 1 day is fine
  private static DocsTokenExpiryBuffer = 3600 * 24;
  private token?: string;
  private refreshHandle: any;

  public constructor(parent: ParentClient) {
    super(parent, "/auth");
  }

  private readonly endpoint: string = "";

  public get accessToken() {
    return this.token;
  }

  public get tokenClaims(): AuthClaims | undefined {
    if (!this.token) {
      return undefined;
    }
    try {
      const decodedJwt = jwtDecode<any>(this.token);
      if (!decodedJwt?.sub) {
        console.warn("Malformed access token payload");
        return undefined;
      }
      const orgClaims = decodedJwt["https://stytch.com/organization"];
      const sessionClaims = decodedJwt["https://stytch.com/session"];
      const orgId = orgClaims?.organization_id;
      const slug = orgClaims?.slug;
      const userId = decodedJwt.sub;
      const role = decodedJwt.role;
      const sessionId = sessionClaims?.id;
      const permissions = getPermissions(role);
      return { orgId, slug, userId, role, permissions, sessionId };
    } catch (err) {
      console.warn("Problem decoding access token");
    }
    return undefined;
  }

  private static tokenExpiryInSeconds(token?: string) {
    if (!token) {
      return -1;
    }

    const exp = jwtDecode<JwtPayload>(token)?.exp;
    if (!exp) {
      return -1;
    }
    return exp - Date.now() / 1000;
  }

  private setToken(token: string) {
    this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    this.token = token;
    const expiry = Auth.tokenExpiryInSeconds(token);

    // Set access token as a temporary cookie
    Cookies.set("access_token", token, { expires: new Date(new Date().getTime() + (expiry - Auth.TokenExpiryBuffer) * 1000) });

    if (expiry > Auth.TokenExpiryBuffer) {
      const timeoutSeconds = expiry - Auth.TokenExpiryBuffer;
      console.debug(`Queuing token refresh in ${timeoutSeconds}`);
      clearTimeout(this.refreshHandle);
      this.refreshHandle = setTimeout(this.refreshAccessToken, timeoutSeconds * 1000);
    }
  }

  public checkToken(token?: string, expiryBuffer = Auth.TokenExpiryBuffer) {
    const expiry = Auth.tokenExpiryInSeconds(token);
    return expiry > expiryBuffer;
  }

  public clearToken() {
    delete this.instance.defaults.headers["Authorization"];
    this.token = undefined;
    Cookies.remove("access_token");
    clearTimeout(this.refreshHandle);
  }

  public refreshAccessToken = async () => {
    const token = Cookies.get("access_token");
    if (token && this.checkToken(token)) {
      this.setToken(token);
      return { success: true };
    } else {
      console.debug("Token is not valid:", token);
      try {
        const res = await this.instance.get<{
          accessToken?: string;
          success: boolean;
          message?: TokenError;
        }>(`${this.endpoint}/refresh`, {
          withCredentials: true,
        });
        if (res.status === 200) {
          const token = res.data?.accessToken;
          if (token) {
            console.debug("Refreshed access token");
            this.setToken(token);
          }
          return { token, success: true };
        }
      } catch (err) {
        console.debug("Failed refreshing token:", err);
      }
      this.clearToken();
      return { success: false, message: "unknown error" };
    }
  };

  public signUp = async (data: { userData: UserData; orgData: OrgData }) => {
    const formData = new FormData();
    formData.append("email", data.userData.email);
    formData.append("name", data.userData.name);
    formData.append("role", data.userData.role);
    formData.append("department", data.userData.department);
    formData.append("orgName", data.orgData.name);
    formData.append("kind", data.orgData.kind);
    formData.append("slug", data.orgData.slug);
    if (data.orgData.size) {
      formData.append("size", data.orgData.size);
    }
    if (data.orgData.logo) {
      formData.append("logo", data.orgData.logo);
    }
    if (data.userData.avatar) {
      formData.append("avatar", data.userData.avatar);
    }
    return await this.instance.post(`${this.endpoint}/sign-up`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  public getPendingUser = async (email: string): Promise<boolean> => {
    const response: AxiosResponse<boolean> = await this.instance.post(`${this.endpoint}/get-pending-user`, { email });
    return response.data;
  };

  public getDocsToken = async () => {
    const token = localStorage.getItem("rollup_docs_token");
    if (token && this.checkToken(token, Auth.DocsTokenExpiryBuffer)) {
      return token;
    }
    try {
      const res = await this.instance.get<{ docsAccessToken: string }>(`${this.endpoint}/get-docs-token`);
      if (res.status === 200 && res.data?.docsAccessToken) {
        localStorage.setItem("rollup_docs_token", res.data?.docsAccessToken);
        return res.data?.docsAccessToken;
      }
    } catch (err) {
      console.warn(err);
    }
    return undefined;
  };

  public checkPasswordStrength = (password: string): Promise<AxiosResponse<PasswordValidationResult>> => {
    return this.instance.post(`${this.endpoint}/passwords/strength_check`, { password });
  };
}
