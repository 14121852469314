import { Button, Tooltip } from "@blueprintjs/core";

type Props = {
  showPassword: boolean;
  toggleShowPassword: () => void;
};

const PassLockIcon = (props: Props) => {
  const { showPassword, toggleShowPassword } = props;

  return (
    <Tooltip content={`${showPassword ? "Hide" : "Show"} Password`}>
      <Button icon={showPassword ? "eye-open" : "eye-off"} minimal onClick={toggleShowPassword} />
    </Tooltip>
  );
};

export default PassLockIcon;
