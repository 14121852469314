export enum Pages {
  Login = "login",
  SignUp = "signup",
  Onboarding = "onboarding",
  Invite = "invite",
  Organizations = "organizations",
  ForgotPassword = "forgot-password",
  ResetPassword = "reset-password",
}

export type AuthState = {
  redirect?: string;
  domain?: string;
};
