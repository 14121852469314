import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import slugify from "slugify";

import { OrgData } from "src/api/auth/types";
import AvatarInput from "src/components/AvatarInput";
import Footer from "src/components/Footer";
import Input from "src/components/Input";
import OrganizationInput from "src/components/OrganizationInput";
import Select from "src/components/Select";
import SidebarPlaceholder from "src/components/SidebarPlaceholder";
import StepsIndicator from "src/components/StepsIndicator";
import Title, { TitleSize } from "src/components/Title";

import { ONBOARDING_STEPS, OnboardingStep } from "../Onboarding";

type Props = {
  currentStep: OnboardingStep;
  handleSubmit: () => void;
  setStep: (step: OnboardingStep) => void;
  orgData: OrgData;
  loading: boolean;
  setOrgData: (orgData: OrgData) => void;
};

const OrganizationData = (props: Props) => {
  const { loading, setStep, orgData, setOrgData, currentStep, handleSubmit } = props;
  const nextButtonDisabled = !orgData.name || !orgData.kind || !orgData.size || !orgData.slug || loading;

  const handleNameChange = (name: string) => {
    setOrgData({ ...orgData, name });
  };

  const handleNameKeyUp = () => {
    const slug = orgData.name ? slugify(orgData.name, { lower: true }) : "";
    setOrgData({ ...orgData, slug });
  };

  return (
    <>
      <div className="onboarding--content">
        <div className="onboarding--form">
          <StepsIndicator current={currentStep} amount={ONBOARDING_STEPS} />
          <Title>Set up organization</Title>
          <Title size={TitleSize.H4}>Please answer several quick questions and help us tailor your experience to your needs</Title>
          <AvatarInput file={orgData.logo} setFile={logo => setOrgData({ ...orgData, logo })} />
          <Input onKeyUp={handleNameKeyUp} label="Organization name" value={orgData.name} onChange={handleNameChange} />
          <OrganizationInput disabled label="Organization slug" defaultValue={orgData.slug} hideNotice />
          <Select
            placeholder="Select kind"
            label="What kind of work do you do?"
            selectedItem={orgData.kind}
            items={["Hardware", "Software"]}
            onSelect={kind => setOrgData({ ...orgData, kind })}
          />
          <Select
            placeholder="Select size"
            label="What is your organisation size?"
            selectedItem={orgData.size || ""}
            items={["Small", "Medium", "Large"]}
            onSelect={size => setOrgData({ ...orgData, size })}
          />
          <Button loading={loading} disabled={nextButtonDisabled} onClick={handleSubmit} large fill intent={Intent.PRIMARY}>
            Continue
          </Button>
          <Button disabled={loading} onClick={() => setStep(OnboardingStep.Organizations)} large minimal fill icon="chevron-left">
            Back
          </Button>
        </div>
        <Footer />
      </div>
      <div className="onboarding--sidebar">
        <SidebarPlaceholder />
      </div>
    </>
  );
};

export default observer(OrganizationData);
