import { NonIdealState } from "@blueprintjs/core";
import classNames from "classnames";

import { RollupIcon } from "../../assets/icons";

import "./Loading.scss";

interface LoadingProps {
  className?: string;
  disableAnimation?: boolean;
}

/** Main function. */
function Loading(props: LoadingProps) {
  const { className, disableAnimation } = props;

  return (
    <NonIdealState className={classNames("loading", className)}>
      <div className={"loading--content"}>
        <RollupIcon className={classNames({ "loading--icon-animation": !disableAnimation })} />
        <div className={"loading--text"}>Loading...</div>
      </div>
    </NonIdealState>
  );
}

/** Exports. */
export default Loading;
