import { useSearchParams } from "react-router-dom";

type Key = string | number | symbol;

const useSearchParam = <V, K extends Key>(key: K) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return [
    searchParams.get(key.toString()) || "",
    (value: V) => setSearchParams(old => new URLSearchParams({ ...old, [key]: value })),
  ] as const;
};

export default useSearchParam;
