import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AnchorButton, Button, NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import useSearchParam from "src/hooks/useSearchParam";
import appStore from "src/store/AppStore";
import { getFirstPageFromPath, getOrganizationDestinationUrl, isInternalRoute } from "src/utilities/url";

import Loading from "./Loading";

/** Main function. */
function AllSet() {
  const location = useLocation();
  const targetPage = getFirstPageFromPath(location.pathname);
  const [loggingInToOrg, setLoggingInToOrg] = useState(false);
  const [gettingOrg, setGettingOrg] = useState(true);
  const [gettingOrgs, setGettingOrgs] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [requireLogOut, setRequireLogOut] = useState(false);
  const [domain] = useSearchParam("domain");
  const [redirectUrl] = useSearchParam("redirectUrl");

  const currentOrganization = appStore.userModel?.organization;
  const userOrganizations = appStore.userModel?.organizations;

  useEffect(() => {
    appStore.userModel?.getUserOrganization().finally(() => setGettingOrg(false));
    appStore.userModel?.getUserOrganizations().finally(() => setGettingOrgs(false));
  }, []);

  useEffect(() => {
    if (gettingOrg || gettingOrgs) {
      return;
    }

    if (!domain || currentOrganization?.slug === domain) {
      setLoggingInToOrg(true);
      console.debug("Redirecting to organization", currentOrganization?.slug);
      setTimeout(() => {
        const redirect = isInternalRoute(targetPage) ? redirectUrl : location.pathname + location.search;
        window.location.href = getOrganizationDestinationUrl(currentOrganization?.slug, redirect);
      }, 2000);
      return;
    }

    const availableOrg = userOrganizations?.find(org => org.slug === domain);
    if (availableOrg) {
      setLoggingInToOrg(true);
      appStore.userModel
        ?.switchOrganization(availableOrg.id)
        .then(r => {
          if (r) {
            console.debug("Redirecting to organization", availableOrg.slug);
            setTimeout(() => {
              window.location.href = getOrganizationDestinationUrl(availableOrg.slug, redirectUrl, true);
            }, 2000);
          } else {
            setRequireLogOut(true);
          }
        })
        .catch(() => setRequireLogOut(true));
    } else if (domain) {
      setNoAccess(true);
    }
  }, [gettingOrg, gettingOrgs]);

  if (noAccess || requireLogOut) {
    const description = requireLogOut
      ? "You are currently logged in to another organization. Switch organization to visit this link."
      : "You don't have access to this organization or organization does not exist.";
    const title = requireLogOut ? "Not logged in" : "Not found";

    return (
      <NonIdealState
        icon="error"
        title={title}
        description={description}
        action={
          <div className="organizations-list--actions">
            <Button large outlined minimal fill onClick={appStore.logout}>
              Switch organization
            </Button>
            <Button large outlined minimal fill onClick={() => setNoAccess(false)}>
              Go to organizations list
            </Button>
          </div>
        }
      />
    );
  }

  if (gettingOrg || gettingOrgs || loggingInToOrg) {
    return <Loading />;
  }

  return (
    <div className="organizations-list-container">
      <div className="organizations-list">
        <h1 className="organizations-list--title">Current organization:</h1>
        {currentOrganization && (
          <div className="organizations-list--item">
            <div className="organizations-list--item-data">
              <img
                className="organizations-list--item-image"
                width={52}
                height={52}
                src={currentOrganization.logoUrl}
                alt={currentOrganization.name}
              />
              <h3 className="organizations-list--item-title">{currentOrganization.name}</h3>
            </div>
            <AnchorButton
              intent="primary"
              href={getOrganizationDestinationUrl(currentOrganization.slug)}
              className="organizations-list--button"
            >
              Open
            </AnchorButton>
          </div>
        )}
        <Button large outlined minimal fill onClick={appStore.logout}>
          SWITCH ORGANIZATION
        </Button>
      </div>
    </div>
  );
}

/** Exports. */
export default observer(AllSet);
