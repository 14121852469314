import { observer } from "mobx-react";

import { useThemeSwitcher } from "src/hooks/useThemeSwitcher/useThemeSwitcher";
import { initDatadog } from "src/lib/Datadog";
import { initSegment } from "src/lib/Segment";
import AppRouter from "src/router/AppRouter";

import "./App.scss";

initDatadog();
initSegment();

/** Main function. */
function App() {
  useThemeSwitcher();

  return (
    <div>
      <AppRouter />
    </div>
  );
}

/** Exports. */
export default observer(App);
