import { KeyboardEventHandler } from "react";
import { InputGroup } from "@blueprintjs/core";

type Props = {
  value: string;
  onChange: (value: string) => void;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  label?: string;
};

import "./Input.scss";

const Input = (props: Props) => {
  const { onKeyUp, label, onChange, value } = props;

  return (
    <label>
      {label && <div className="input--label">{label}</div>}
      <InputGroup
        onKeyUp={onKeyUp}
        placeholder="Enter here"
        large
        className="input"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </label>
  );
};

export default Input;
