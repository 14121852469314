import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnchorButton, Button, Checkbox, Divider, Intent, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import GmailIcon from "src/assets/icons/gmailIcon.svg?react";
import GoogleIcon from "src/assets/icons/googleIcon.svg?react";
import OutlookIcon from "src/assets/icons/outlookIcon.svg?react";
import EmailInput from "src/components/EmailInput";
import Footer from "src/components/Footer";
import { CustomIcon } from "src/components/Shared/CustomIcon/CustomIcon";
import SidebarPlaceholder from "src/components/SidebarPlaceholder";
import Title, { TitleSize } from "src/components/Title";
import useForm from "src/hooks/useForm";
import appStore from "src/store/AppStore";
import { isValid } from "src/utilities/email";

import { Pages } from "../types";

import "./SignUp.scss";

/** Main function. */
function SignUp() {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const redirect = Pages.Onboarding;
  const { setEmail, email } = useForm();

  const togglePolicyAccepted = () => setPolicyAccepted(!policyAccepted);

  const handleGetLink = () => {
    if (email) {
      appStore.getMagicLink(email, undefined, redirect, true).then(() => setSent(true));
    }
  };

  const handleGoogleAuth = () => appStore.googleLogin("", redirect);

  const renderSuccess = () => (
    <>
      <Title>Check your email</Title>
      <Title size={TitleSize.H4}>Magic link has been sent to the provided email address</Title>
      <div className="sign-up--email-buttons">
        <AnchorButton icon={<CustomIcon icon={<GmailIcon />} />} target="_blank" href="https://mail.google.com/" large outlined fill>
          Open Gmail
        </AnchorButton>
        <AnchorButton
          icon={<CustomIcon icon={<OutlookIcon />} />}
          target="_blank"
          href="https://outlook.live.com/mail/"
          large
          outlined
          fill
        >
          Open Outlook
        </AnchorButton>
      </div>
      <div className="sign-up--buttons">
        <Button alignText="left" icon="arrow-left" onClick={() => setSent(false)} large minimal>
          Back to Sign up
        </Button>
        <Button alignText="right" onClick={handleGetLink} large minimal>
          Resend magic link
        </Button>
      </div>
    </>
  );

  const getSignUpTooltip = () => {
    if (!email) {
      return "Enter your email address first";
    }

    if (!isValid(email)) {
      return "Enter a valid email address";
    }

    return "Accept the policy first";
  };

  const renderForm = () => (
    <>
      <Title>Get started</Title>
      <Title size={TitleSize.H4}>
        Please use your work or institutional email, even if you aren't intending to use Rollup on behalf of your ogranization yet
      </Title>
      <Tooltip content="Accept the policy first" disabled={policyAccepted}>
        <AnchorButton
          disabled={!policyAccepted}
          outlined
          minimal
          onClick={handleGoogleAuth}
          icon={<CustomIcon icon={<GoogleIcon />} />}
          fill
          large
        >
          Sign up with Google
        </AnchorButton>
      </Tooltip>
      <Title size={TitleSize.H4}>or use your work email to sign up</Title>
      <EmailInput placeholder="John.Doe@Company.com" onChange={setEmail} email={email} required onEnter={handleGetLink} />
      <Tooltip content={getSignUpTooltip()} disabled={policyAccepted && isValid(email)}>
        <Button
          data-testid="sign-up-btn"
          onClick={handleGetLink}
          disabled={!isValid(email) || appStore.loginLinkPending || !policyAccepted}
          large
          intent={Intent.PRIMARY}
          fill
        >
          Sign up
        </Button>
      </Tooltip>
      <label className="sign-up--policy">
        <Checkbox large checked={policyAccepted} onChange={togglePolicyAccepted} />
        <Title size={TitleSize.Caption}>
          By confirming your email, you agree to our{" "}
          <a href="https://rollup.ai/terms" target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and that you have read and understood our{" "}
          <a href="https://rollup.ai/privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </Title>
      </label>
      <Divider className="sign-up--divider" />
      <Title size={TitleSize.H4}>Already have an account?</Title>
      <Button data-testid="magik-link-btn" onClick={() => navigate("/")} large intent={Intent.PRIMARY} fill>
        Sign in
      </Button>
    </>
  );

  return (
    <div className="sign-up">
      <div className="sign-up--content">
        <div className="sign-up--form">{sent ? renderSuccess() : renderForm()}</div>
        <Footer />
      </div>
      <div className="sign-up--sidebar">
        <SidebarPlaceholder />
      </div>
    </div>
  );
}

/** Exports. */
export default observer(SignUp);
