import classNames from "classnames";

import Title, { TitleSize } from "src/components/Title";

import "./Quote.scss";

type Props = {
  text: string;
  name: string;
  position: string;
  image: string;
  className?: string;
};

const Quote = (props: Props) => {
  const { text, name, position, image, className } = props;

  return (
    <div className={classNames("quote", className)}>
      <Title size={TitleSize.H3}>{text}</Title>
      <div className="quote--author">
        <img src={image} alt="user" className="quote--avatar" />
        <div>
          <Title size={TitleSize.H4}>{name}</Title>
          <div className="quote--position">{position}</div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
