import { Intent } from "@blueprintjs/core";
import { AxiosResponse } from "axios";
import makeInspectable from "mobx-devtools-mst";
import { destroy, flow, Instance, types } from "mobx-state-tree";
import { persistInLocalStorage } from "mobx-state-tree-localstorage";

import { rollupClient } from "src/api";
import { TokenError, UserPermission, UserRole } from "src/api/auth";
import { showToast } from "src/components/UiLayers/toaster";
import { ListOrganizations, Organizations } from "src/models/organizations";
import { PasswordValidationResult } from "src/models/passwords";
import { filterEnumArray } from "src/utilities/array";

import AppUiStore from "./AppUiStore";
import { EnvironmentStore } from "./EnvironmentStore";
import { FeedbackStore } from "./FeedbackStore";
import { IUser, UserStore } from "./UserStore";

export enum AuthStatus {
  Pending,
  FetchingProfile,
  LoggedIn,
  LoggedOut,
}

const AppStore = types
  .model("AppStore", {
    env: types.maybeNull(EnvironmentStore),
    userModel: types.maybeNull(UserStore),
    token: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
  })
  .volatile(() => ({
    feedback: FeedbackStore.create(),
    ui: AppUiStore.create(),
    authenticationPending: true,
    fetchingOrganizations: false,
    loginLinkSent: false,
    loginLinkPending: false,
    organizations: [] as Organizations,
    accessErrorMessage: TokenError.None,
  }))
  .actions(self => ({
    setUserProfile(profile: IUser) {
      self.userModel = UserStore.create(profile);
    },
    googleLogin(domain?: string, redirect?: string) {
      let redirectUrl = `${rollupClient.url}/auth/oauth/google`;
      if (domain) {
        redirectUrl += `?domain=${domain}`;
      }
      if (redirect) {
        redirectUrl += `${domain ? "&" : "?"}redirect=${redirect}`;
      }
      window.location.href = redirectUrl;
    },
  }))
  .actions(self => ({
    getMagicLink: flow(function* getMagicLink(
      email: string,
      domain?: string,
      redirect?: string,
      isSignUp?: boolean
    ): Generator<any, boolean, any> {
      self.loginLinkPending = true;
      try {
        yield rollupClient.stytchAuth.getLink(email, domain, redirect, isSignUp);
        self.loginLinkPending = false;
        showToast("Link sent to your email", Intent.SUCCESS);
      } catch (err) {
        console.warn(err);
        showToast("Can't login with this email", Intent.WARNING);
        self.loginLinkPending = false;
        return false;
      }
      return true;
    }),
    resetPasswordByEmail: flow(function* resetPasswordByEmail(email: string, slug: string): Generator<any, boolean, any> {
      self.loginLinkPending = true;
      try {
        yield rollupClient.stytchAuth.resetPasswordByEmail(email, slug);
        self.loginLinkPending = false;
        showToast("Link sent to your email", Intent.SUCCESS);
      } catch (err) {
        console.warn(err);
        showToast("Error when resetting password", Intent.WARNING);
        self.loginLinkPending = false;
        return false;
      }
      return true;
    }),
    setNewPassword: flow(function* setNewPassword(password: string, token: string): Generator<any, boolean, any> {
      self.loginLinkPending = true;
      try {
        yield rollupClient.stytchAuth.setNewPassword(password, token);
        self.loginLinkPending = false;
        showToast("New password is set", Intent.SUCCESS);
      } catch (err) {
        console.warn(err);
        showToast("Can't set new password", Intent.WARNING);
        self.loginLinkPending = false;
        return false;
      }
      return true;
    }),
    checkPasswordStrength: flow(function* checkPasswordStrength(
      password: string,
      email: string
    ): Generator<any, PasswordValidationResult | undefined, any> {
      self.loginLinkPending = true;
      try {
        const result: AxiosResponse<PasswordValidationResult> = yield rollupClient.stytchAuth.checkPasswordStrength(password, email);
        self.loginLinkPending = false;
        return result.data;
      } catch (err) {
        console.warn(err);
        self.loginLinkPending = false;
        return;
      }
    }),
    passwordLogin: flow(function* passwordLogin(
      slug: string,
      email: string,
      password: string,
      redirect?: string
    ): Generator<any, boolean, any> {
      self.loginLinkPending = true;
      try {
        const res = yield rollupClient.stytchAuth.passwordLogin(slug, email, password);
        const success = res.status === 200;
        self.loginLinkPending = false;
        if (success) {
          showToast("Login succeeded", Intent.SUCCESS);
          let frontendUrl = import.meta.env.VITE_HOST_URL.replace("{{slug}}", slug);
          if (redirect) {
            frontendUrl += redirect;
          }
          window.location.href = frontendUrl;
          return true;
        } else {
          showToast("Invalid username, password or organization ID", Intent.WARNING);
        }
      } catch (err) {
        showToast("Invalid username, password or organization ID", Intent.WARNING);
        console.warn(err);
        self.loginLinkPending = false;
      }
      return false;
    }),
    getOrganizations: flow(function* getOrganizations(sessionId: string): Generator<any, boolean, any> {
      self.fetchingOrganizations = true;
      try {
        const res: ListOrganizations | undefined = yield rollupClient.stytchAuth.getOrganizations(sessionId);
        if (res) {
          self.organizations = res.discovered_organizations;
          self.token = res.intermediate_session_token;
          self.email = res.email_address;
        }
        self.fetchingOrganizations = false;
      } catch (err) {
        console.warn(err);
        self.fetchingOrganizations = false;
        return false;
      }
      return true;
    }),
  }))
  .actions(self => ({
    logout() {
      rollupClient.auth.clearToken();
      if (self.userModel) {
        destroy(self.userModel);
      }

      if (import.meta.env.NODE_ENV !== "test") {
        console.debug("Redirecting to logout");
        window.location.href = `${rollupClient.url}/auth/logout`;
      }
    },
  }))
  .actions(self => ({
    afterCreate: flow(function* (): Generator<any, void, any> {
      try {
        const tokenResult = yield rollupClient.auth.refreshAccessToken();
        if (!tokenResult?.success) {
          self.authenticationPending = false;
          self.userModel = null;
          self.accessErrorMessage = tokenResult?.message ?? TokenError.Unknown;
        } else {
          self.accessErrorMessage = TokenError.None;
          console.debug("Token was successfully refreshed");
        }
        self.authenticationPending = false;
        const userInfo = yield rollupClient.profiles.retrieveProfile();
        if (!userInfo?.data?.id) {
          console.error("Error fetching user data", new Error());
          self.authenticationPending = false;
          self.userModel = null;
          return;
        }

        const tokenClaims = rollupClient.auth.tokenClaims;
        userInfo.data.permissions = filterEnumArray(UserPermission, tokenClaims?.permissions);
        userInfo.data.orgId = tokenClaims?.orgId;
        userInfo.data.roles = filterEnumArray(UserRole, userInfo.data.roles);

        const userProfile: IUser = structuredClone(userInfo.data);

        self.setUserProfile(userProfile);
        self.authenticationPending = false;
      } catch (error) {
        console.warn(error);
        self.authenticationPending = false;
        self.userModel = null;
        return;
      }
    }),
  }))
  .views(self => ({
    get authStatus(): AuthStatus {
      if (self.authenticationPending) {
        return AuthStatus.Pending;
      }
      if (self.userModel?.id) {
        return AuthStatus.LoggedIn;
      }
      return self.accessErrorMessage === TokenError.None ? AuthStatus.FetchingProfile : AuthStatus.LoggedOut;
    },
  }));

export type IAppStore = Instance<typeof AppStore>;

const envStore = persistInLocalStorage({
  tree: EnvironmentStore,
  id: "@Rollup/env",
  initialState: {
    themeIsDark: true,
  },
});

const appStore = AppStore.create({ env: envStore }) as IAppStore;

makeInspectable(appStore);

export default appStore;
