import { Intent } from "@blueprintjs/core";

import { showToast } from "src/components/UiLayers/toaster";
import { formatFileSize } from "src/utilities/file";

const useFileUploadMessage = () => {
  const showFormatErrorMessage = (file: File) => {
    showToast(`${file.name} format is not supported!`, Intent.DANGER);
  };

  const showSizeErrorMessage = (file: File, limit: number) => {
    showToast(`${file.name} size is too big, ${formatFileSize(file.size)}! Allowed size: ${formatFileSize(limit)}`, Intent.DANGER);
  };

  return {
    showFormatErrorMessage,
    showSizeErrorMessage,
  };
};

export default useFileUploadMessage;
