import { Button, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select as BPSelect } from "@blueprintjs/select";

import "./Select.scss";

type Props = {
  onSelect: (item: string) => void;
  items: string[];
  selectedItem: string;
  label?: string;
  placeholder?: string;
};

const Select = (props: Props) => {
  const { onSelect, items, label, selectedItem, placeholder } = props;

  const renderItem: ItemRenderer<string> = (item, { handleClick }) => {
    return <MenuItem active={selectedItem === item} key={item} onClick={handleClick} text={item} />;
  };

  return (
    <label>
      {label && <div className="select--label">{label}</div>}
      <BPSelect<string>
        className="select"
        filterable={false}
        popoverProps={{ minimal: true }}
        items={items}
        itemRenderer={renderItem}
        onItemSelect={onSelect}
      >
        <Button text={selectedItem || placeholder || "Select"} rightIcon="chevron-down" fill alignText="left" />
      </BPSelect>
    </label>
  );
};

export default Select;
