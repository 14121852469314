import classNames from "classnames";

import "./Avatar.scss";

export enum AvatarSize {
  Small = "small",
  Large = "large",
}

export enum AvatarShape {
  Circle = "circle",
  Square = "square",
}

type Props = {
  size?: AvatarSize;
  shape?: AvatarShape;
  imgUrl?: string;
  letter?: string;
  isOnline?: boolean;
  className?: string;
};

const Avatar = (props: Props) => {
  const { isOnline, letter, shape = AvatarShape.Circle, size = AvatarSize.Small, imgUrl } = props;
  const { className } = props;

  const renderPlaceholder = () => letter?.toUpperCase() ?? "A";

  return (
    <div
      className={classNames(`avatar avatar--${size} avatar--${shape}`, className, { ["avatar--online"]: isOnline })}
      style={{ backgroundImage: `url("${imgUrl}")` }}
    >
      {!imgUrl && renderPlaceholder()}
    </div>
  );
};

export default Avatar;
