import React, { useState } from "react";

const useForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [terms, setTerms] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  const toggleTerms = () => setTerms(!terms);

  return {
    password,
    setPassword,
    name,
    onNameChange,
    email,
    setEmail,
    terms,
    toggleTerms,
  };
};

export default useForm;
