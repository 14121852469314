import axios, { AxiosInstance } from "axios";

import { rollupClient } from "./";

export interface ParentClient {
  url: string;
  apiPrefix: string;
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected constructor(
    protected readonly parent: ParentClient,
    protected readonly apiPrefix?: string
  ) {
    this.instance = axios.create({
      baseURL: parent.url + (apiPrefix ?? parent.apiPrefix),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this._initializeRequestInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(requestConfig => {
      const accessToken = rollupClient.auth.accessToken;

      if (accessToken && requestConfig.headers) {
        requestConfig.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return requestConfig;
    });
  };
}
