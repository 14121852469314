import { Profile } from "src/models/profiles";

import { HttpClient, ParentClient } from "../client";

export class Profiles extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/profile";

  public retrieveProfile = () => {
    return this.instance.get<Profile>(`${this.endpoint}`);
  };
}
