import { Instance, types } from "mobx-state-tree";

export const EnvironmentStore = types
  .model("Environment", {
    themeIsDark: types.boolean,
  })
  .actions(self => ({
    toggleTheme() {
      self.themeIsDark = !self.themeIsDark;
    },
    setThemeIsDark(isDark: boolean) {
      self.themeIsDark = isDark;
    },
  }));

export interface IEnvironment extends Instance<typeof EnvironmentStore> {}
