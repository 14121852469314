export class UserOrganization {
  public readonly allowedDomains!: string[];
  public readonly id!: string;
  public readonly logoUrl!: string;
  public readonly name!: string;
  public readonly slug!: string;
}

export class OrganizationData {
  public readonly organization_id!: string;
  public readonly organization_slug!: string;
  public readonly organization_name!: string;
  public readonly organization_logo_url!: string;
  public readonly trusted_metadata?: {
    status?: OrganizationStatus;
  };
}

export class OrganizationMember {
  public readonly member_id!: string;
  public readonly name!: string;
  public readonly organization_id!: string;
  public readonly status!: string;
  public readonly email_address!: string;
}

export enum MembershipType {
  Active = "active_member",
  Pending = "pending_member",
  Invited = "invited_member",
  Eligible = "eligible_to_join_by_email_domain",
}

export class OrganizationMembershipData {
  public readonly type!: MembershipType;
  public readonly member!: OrganizationMember;
}

export enum OrganizationStatus {
  Active = "active",
  Pending = "pending",
  Deactivated = "deactivated",
}

export class Organization {
  public readonly organization!: OrganizationData;
  public readonly membership!: OrganizationMembershipData;
  public readonly member_authenticated!: boolean;
  public readonly trusted_metadata?: {
    status?: OrganizationStatus;
  };
}

export type Organizations = Array<Organization>;

export type ListOrganizations = {
  request_id: string;
  status_code: number;
  email_address: string;
  discovered_organizations: Organizations;
  intermediate_session_token: string;
};
