import { AxiosResponse } from "axios";
import { cast, flow, Instance, types } from "mobx-state-tree";

import { rollupClient } from "src/api";
import { UserPermission, UserRole } from "src/api/auth";
import { UserOrganization } from "src/models/organizations";

export const UserStore = types
  .model("User", {
    id: types.identifier,
    email: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    avatarUrl: types.maybeNull(types.string),
    orgId: types.maybeNull(types.string),
    mostRecentWorkspace: types.optional(types.string, ""),
    permissions: types.optional(types.array(types.enumeration("UserPermission", [...Object.values(UserPermission)])), []),
    role: types.optional(types.enumeration("UserRole", [...Object.values(UserRole)]), UserRole.user),
  })
  .volatile(() => ({
    organization: undefined as UserOrganization | undefined,
    organizations: undefined as UserOrganization[] | undefined,
  }))
  .actions(self => {
    return {
      setUserData(data: IUser) {
        self.email = data.email;
        self.avatarUrl = data.avatarUrl;
      },
      switchOrganization: flow(function* switchOrganization(orgId: string): Generator<any, boolean, any> {
        try {
          const res = yield rollupClient.stytchAuth.exchangeSession(orgId);
          if (res.success) {
            self.orgId = orgId;
            self.mostRecentWorkspace = "";
          }
        } catch (err) {
          console.warn(err);
          return false;
        }
        return true;
      }),
      getUserOrganizations: flow(function* getUserOrganizations(): Generator<any, boolean, any> {
        try {
          const res = yield rollupClient.organizations.getOrganizations();
          if (res) {
            self.organizations = res.data;
          }
        } catch (err) {
          console.warn(err);
          return false;
        }
        return true;
      }),
      getUserOrganization: flow(function* getUserOrganization(): Generator<any, boolean, any> {
        try {
          const res: AxiosResponse<UserOrganization> = yield rollupClient.organizations.getUserOrg();
          if (res) {
            self.organization = res.data;
          }
        } catch (err) {
          console.warn(err);
          return false;
        }
        return true;
      }),
    };
  })
  .actions(self => ({
    setRole(role: UserRole) {
      self.role = cast(role);
    },
    setPictureUrl(avatarUrl: string) {
      self.avatarUrl = avatarUrl;
    },
    setName(name: string) {
      self.name = name;
    },
  }))
  .views(self => ({
    get displayName() {
      return self.name ?? self.email ?? "";
    },
  }));

export interface IUser extends Instance<typeof UserStore> {}
