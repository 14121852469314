import classNames from "classnames";
import { observer } from "mobx-react";

import ChiplyticsDark from "src/assets/img/companies/dark/chiplytics.svg";
import NerosDark from "src/assets/img/companies/dark/neros.svg";
import OrangewoodDark from "src/assets/img/companies/dark/orangewood.svg";
import SparkmateDark from "src/assets/img/companies/dark/sparkmate.svg";
import VuecasonDark from "src/assets/img/companies/dark/vuecason.svg";
import ChiplyticsLight from "src/assets/img/companies/light/chiplytics.svg";
import NerosLight from "src/assets/img/companies/light/neros.svg";
import OrangewoodLight from "src/assets/img/companies/light/orangewood.svg";
import SparkmateLight from "src/assets/img/companies/light/sparkmate.svg";
import VuecasonLight from "src/assets/img/companies/light/vuecason.svg";
import appStore from "src/store/AppStore";

import "./CompaniesRow.scss";

type Props = {
  className?: string;
};

const CompaniesRow = (props: Props) => {
  const darkTheme = appStore.env?.themeIsDark;

  return (
    <div className={classNames("companies-row", props.className)}>
      <img style={{ minWidth: 75 }} src={darkTheme ? ChiplyticsDark : ChiplyticsLight} alt="chiplytics" />
      <img style={{ minWidth: 94 }} src={darkTheme ? SparkmateDark : SparkmateLight} alt="sparkmate" />
      <img style={{ minWidth: 96 }} src={darkTheme ? OrangewoodDark : OrangewoodLight} alt="orangewood" />
      <img style={{ minWidth: 85 }} src={darkTheme ? VuecasonDark : VuecasonLight} alt="vuecason" />
      <img style={{ minWidth: 85 }} src={darkTheme ? NerosDark : NerosLight} alt="neros" />
    </div>
  );
};

export default observer(CompaniesRow);
