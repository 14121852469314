import userImage from "src/assets/img/matthew-skeels.jpeg?url";
import CompaniesRow from "src/components/CompaniesRow";
import Quote from "src/components/Quote";
import Title, { TitleSize } from "src/components/Title";

import "./SidebarPlaceholder.scss";

const SidebarPlaceholder = () => (
  <>
    <div className="sidebar-placeholder--content">
      <Title>The Platform for Moonshots</Title>
      <Title size={TitleSize.H4}>The modern collaboration, low-code platform for engineers</Title>
      <Quote
        className="sidebar-placeholder--quote"
        image={userImage}
        name="Matthew Skeels"
        position="Co-founder, Chiplytics"
        text='"Rollup has been a game changer for our design reviews. Uploading CAD models is a breeze, and everything’s super intuitive. Plus, the customer service is awesome. Highly recommend it if you’re in the industry!"'
      />
    </div>
    <CompaniesRow className="sidebar-placeholder--companies" />
  </>
);

export default SidebarPlaceholder;
