import { FormEvent } from "react";
import { Button, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import exampleDarkImg from "src/assets/img/example-dark.svg";
import exampleLightImg from "src/assets/img/example-light.svg";
import Footer from "src/components/Footer";
import StepsIndicator from "src/components/StepsIndicator";
import Title, { TitleSize } from "src/components/Title";
import appStore from "src/store/AppStore";

import { ONBOARDING_STEPS, OnboardingStep } from "../Onboarding";

import "./Theme.scss";

enum Theme {
  Dark = "dark",
  Light = "light",
}

type Props = {
  currentStep: OnboardingStep;
  setStep: (step: OnboardingStep) => void;
};

const ThemeSelect = (props: Props) => {
  const { setStep, currentStep } = props;

  const handleThemeChanged = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    appStore.env?.setThemeIsDark(target.value === Theme.Dark);
  };

  const themeValue = appStore.env?.themeIsDark ? Theme.Dark : Theme.Light;

  return (
    <>
      <div className="onboarding--content">
        <div className="onboarding--form">
          <StepsIndicator current={currentStep} amount={ONBOARDING_STEPS} />
          <Title>By the way, we have two modes.</Title>
          <Title size={TitleSize.H4}>Which one do you prefer?</Title>
          <RadioGroup onChange={handleThemeChanged} selectedValue={themeValue}>
            <Radio large label="Dark" value={Theme.Dark} />
            <Radio large label="Light" value={Theme.Light} />
          </RadioGroup>
          <Button onClick={() => setStep(OnboardingStep.Organizations)} large fill intent={Intent.PRIMARY}>
            Continue
          </Button>
          <Button onClick={() => setStep(OnboardingStep.UserData)} large minimal fill icon="chevron-left">
            Back
          </Button>
        </div>
        <Footer />
      </div>
      <div className="onboarding--sidebar onboarding-theme--sidebar">
        <div className="sidebar-placeholder--content onboarding-theme--sidebar-content">
          <Title size={TitleSize.H2}>Watch out how the system can look like in both modes and select the best one for yourself</Title>
          <img className="onboarding-theme--sidebar-img" src={appStore.env?.themeIsDark ? exampleDarkImg : exampleLightImg} alt="example" />
        </div>
      </div>
    </>
  );
};

export default observer(ThemeSelect);
