import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import OrganizationsList from "src/components/OrganizationsList";
import appStore from "src/store/AppStore";

import Loading from "../Loading";

import "./Organizations.scss";

/** Main function. */
function Organizations() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!appStore.token && !appStore.fetchingOrganizations) {
      navigate("/");
    }
  }, []);

  if (appStore.fetchingOrganizations) {
    return <Loading />;
  }

  if (!appStore.organizations.length) {
    return (
      <div className="organizations-list">
        <h1 className="organizations-list--title">No organizations attached to email{appStore.email && `: ${appStore.email}`}</h1>
        <Button large fill intent={Intent.PRIMARY} onClick={() => navigate("/")}>
          Try another email
        </Button>
      </div>
    );
  }

  return (
    <div className="organizations-list-container">
      <div className="organizations-list-container--inner">
        <OrganizationsList organizations={appStore.organizations} />
      </div>
    </div>
  );
}

/** Exports. */
export default observer(Organizations);
