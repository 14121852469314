import React, { useEffect, useState } from "react";
import { InputGroup, Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import PassLockIcon from "../PassLockIcon";

import "./PasswordInput.scss";

type Props = {
  password: string;
  placeholder?: string;
  loading?: boolean;
  minLength?: number;
  onChange: (password: string) => void;
  onEnter?: () => void;
  label?: string;
  className?: string;
};

const PasswordInput = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { className, password, onChange, placeholder, minLength, loading, onEnter, label } = props;
  const [error, setError] = useState("");

  useEffect(() => {
    if (password && minLength && password.length < minLength) {
      setError("Password length is less then 8 characters");
    } else {
      setError("");
    }
  }, [password]);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onEnter?.();
    }
  };

  return (
    <>
      {label && <div className="input--label">{label}</div>}
      <InputGroup
        fill
        autoComplete="off"
        large
        className={classNames("password-input", className)}
        placeholder={placeholder ?? "Password"}
        rightElement={
          loading ? <Spinner size={16} /> : <PassLockIcon showPassword={showPassword} toggleShowPassword={toggleShowPassword} />
        }
        onKeyDown={handleKeyDown}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handlePasswordChange}
      />
      {error && <div className="password-input--error">{error}</div>}
    </>
  );
};

export default PasswordInput;
