import React, { useState } from "react";
import { InputGroup, Intent } from "@blueprintjs/core";

import { isValid } from "src/utilities/email";

import "./EmailInput.scss";

type Props = {
  onChange: (email: string) => void;
  email: string;
  placeholder?: string;
  required: boolean;
  onEnter?: () => void;
};

const EmailInput = ({ onChange, email, required, onEnter, placeholder }: Props) => {
  const [error, setError] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    onChange(newEmail);
    if (required && !newEmail) {
      setError("This is required field");
    } else if (!isValid(newEmail)) {
      setError("Email address is invalid");
    } else {
      setError("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onEnter?.();
    }
  };

  return (
    <div>
      <InputGroup
        autoComplete="off"
        fill
        intent={error ? Intent.DANGER : Intent.NONE}
        large
        type="email"
        required
        placeholder={placeholder || "Email"}
        onChange={handleEmailChange}
        value={email}
        onKeyDown={handleKeyDown}
      />
      {error && <div className="email-input--error">{error}</div>}
    </div>
  );
};

export default EmailInput;
