import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AnchorButton, Button, Divider, Intent } from "@blueprintjs/core";
import Cookies from "js-cookie";
import { observer } from "mobx-react";

import GoogleIcon from "src/assets/icons/googleIcon.svg?react";
import EmailInput from "src/components/EmailInput";
import Footer from "src/components/Footer";
import OrganizationInput from "src/components/OrganizationInput";
import PasswordInput from "src/components/PasswordInput";
import { CustomIcon } from "src/components/Shared/CustomIcon/CustomIcon";
import useForm from "src/hooks/useForm";
import useSearchParam from "src/hooks/useSearchParam";
import { GOVCLOUD_MODE } from "src/lib/GovCloud";
import appStore from "src/store/AppStore";
import { isValid } from "src/utilities/email";
import { getFirstPageFromPath, isInternalRoute } from "src/utilities/url";

import { AuthState, Pages } from "../types";

import "./Login.scss";

enum LoginMode {
  LINK = "link",
  PASSWORD = "password",
}

/** Main function. */
function Login() {
  const location = useLocation();
  const targetPage = getFirstPageFromPath(location.pathname);
  const [loginMode, setLoginMode] = useState(LoginMode.LINK);
  const [sessionId] = useSearchParam("sessionId");
  const [state] = useSearchParam("state");
  const [domain] = useSearchParam("domain");
  const [slug, setSlug] = useState(domain || Cookies.get("org_slug") || "");
  const [redirect, setRedirect] = useSearchParam("redirectUrl");
  const [help] = useSearchParam("help");
  const { setEmail, email, setPassword, password } = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const disableGoogleLogin = import.meta.env.VITE_DISABLE_GOOGLE === "true";
  const disableMagicLinks = GOVCLOUD_MODE;

  useEffect(() => {
    if (redirect.match(/^\/?logout/)) {
      setRedirect("");
    }
  }, [redirect]);

  useEffect(() => {
    if (targetPage && !isInternalRoute(targetPage)) {
      navigate(`/?redirectUrl=${location.pathname}`);
    }
  }, [targetPage]);

  useEffect(() => {
    if (sessionId) {
      appStore.getOrganizations(sessionId);
      if (state) {
        try {
          const decodedState: AuthState = JSON.parse(atob(state));
          if (decodedState.redirect === Pages.Onboarding) {
            navigate("/onboarding");
          } else {
            let destination = "/organizations";
            if (decodedState.domain) {
              destination += `?domain=${decodedState.domain}`;
            }
            if (decodedState.redirect) {
              destination += `${decodedState.domain ? "&" : "?"}redirectUrl=${decodedState.redirect}`;
            }
            navigate(destination);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        navigate("/organizations");
      }
    }
  }, [sessionId, state]);

  const handleGetLink = () => {
    if (email && !disableMagicLinks) {
      Cookies.set("org_slug", domain);
      appStore.getMagicLink(email, domain, redirect);
    }
  };

  const handlePassAuth = () => {
    if (slug && password && isValid(email)) {
      Cookies.set("org_slug", slug);
      appStore.passwordLogin(slug, email, password, redirect);
    }
  };

  const handleGoogleAuth = () => {
    appStore.googleLogin(domain, redirect);
  };

  useEffect(() => {
    if (help) {
      appStore.feedback.show();
      searchParams.delete("help");
      navigate({ search: searchParams.toString() });
    }
  }, [help]);

  const renderLinkLogin = () => (
    <>
      <h1 className="login-form--title">Sign in</h1>
      {!disableGoogleLogin && (
        <AnchorButton outlined minimal onClick={handleGoogleAuth} icon={<CustomIcon icon={<GoogleIcon />} />} fill large>
          Sign in with Google
        </AnchorButton>
      )}
      <div className="login-form--divide">or use your email to sign in</div>
      <EmailInput onChange={setEmail} email={email} required onEnter={handleGetLink} />
      <div style={{ display: loginMode === LoginMode.PASSWORD ? "block" : "none" }}>
        <PasswordInput password={password} onChange={setPassword} />
      </div>
      <div className="login-form--actions">
        <Button data-testid="password-login-btn" onClick={() => setLoginMode(LoginMode.PASSWORD)} fill large outlined>
          Sign in with password
        </Button>
        {!disableMagicLinks && (
          <Button
            data-testid="magik-link-btn"
            onClick={handleGetLink}
            disabled={!isValid(email) || appStore.loginLinkPending}
            large
            intent={Intent.PRIMARY}
            fill
          >
            Get magic link
          </Button>
        )}
      </div>
      <Divider className="login-form--divider" />
      <div className="login-form--divide">New to Rollup?</div>
      <Button data-testid="magik-link-btn" onClick={() => navigate("/signup")} large outlined>
        Sign up
      </Button>
    </>
  );

  const renderPasswordLogin = () => (
    <>
      <h1 className="login-form--title">Help us identify you</h1>
      <div className="login-form--divide">Enter your Organization’s url</div>
      <OrganizationInput onEnter={handlePassAuth} defaultValue={domain} onChange={setSlug} />
      <Divider />
      <span>Your email</span>
      <EmailInput onEnter={handlePassAuth} onChange={setEmail} email={email} required />
      <span>Your password</span>
      <PasswordInput onEnter={handlePassAuth} password={password} onChange={setPassword} />
      <Button
        onClick={handlePassAuth}
        disabled={!isValid(email) || appStore.loginLinkPending || !password || !slug}
        large
        intent={Intent.PRIMARY}
        fill
      >
        Sign in
      </Button>
      <div className="login-form--actions">
        <Button alignText="left" icon="arrow-left" onClick={() => setLoginMode(LoginMode.LINK)} minimal large fill>
          Back
        </Button>
        <Button alignText="right" onClick={() => navigate("/forgot-password")} minimal large fill>
          Forgot your password?
        </Button>
      </div>
    </>
  );

  return (
    <div className="login">
      <div className="login-form">{loginMode === LoginMode.LINK ? renderLinkLogin() : renderPasswordLogin()}</div>
      <Footer />
    </div>
  );
}

/** Exports. */
export default observer(Login);
