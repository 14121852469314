import { AxiosResponse } from "axios";

import { ListOrganizations } from "src/models/organizations";
import { PasswordValidationResult } from "src/models/passwords";

import { HttpClient, ParentClient } from "../client";

type GetOrganizationsResponse = "" | ListOrganizations;

enum MagicLinkActionType {
  SignIn = "sign_in",
  SignUp = "sign_up",
}

export class StytchAuth extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  public getOrganizations = async (sessionId: string) => {
    const res = await this.instance.get<GetOrganizationsResponse>(`auth/session?id=${sessionId}`);
    return res.data || undefined;
  };

  public getLink = async (email: string, domain?: string, redirect?: string, isSignUp?: boolean) => {
    return await this.instance.post("magic-links/send", {
      email,
      domain,
      redirect,
      action: isSignUp ? MagicLinkActionType.SignUp : MagicLinkActionType.SignIn,
    });
  };

  public clearToken() {
    delete this.instance.defaults.headers["Authorization"];
    sessionStorage.removeItem("access_token");
  }

  public exchangeSession = async (orgId: string) => {
    try {
      const res = await this.instance.post<{ accessToken: string }>(`auth/exchange/${orgId}`, undefined, {
        withCredentials: true,
      });
      if (res.status === 200) {
        this.clearToken();
        console.debug(`Exchanged session for new organization ${orgId}`);
        return { success: true };
      }
    } catch (err) {
      console.debug(err);
    }
    this.clearToken();
    return { success: false, message: "unknown error" };
  };

  public passwordLogin = async (slug: string, email: string, password: string) => {
    return this.instance.post("auth/login", { email, password, slug }, { withCredentials: true });
  };

  public resetPasswordByEmail = async (email: string, slug: string) => {
    return this.instance.post("auth/passwords/email/reset/start", { email_address: email, organization_slug: slug });
  };

  public setNewPassword = async (password: string, token: string) => {
    return this.instance.post("auth/passwords/email/reset", { password_reset_token: token, password });
  };

  public checkPasswordStrength = async (password: string, email: string): Promise<AxiosResponse<PasswordValidationResult>> => {
    return this.instance.post("auth/passwords/strength_check", { email_address: email, password });
  };
}
