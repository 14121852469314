import { redirect } from "react-router-dom";

import { rollupClient } from "src/api";
import appStore, { AuthStatus } from "src/store/AppStore";

import Loading from "./Loading";

/** Loader. */
async function appIndexLoader() {
  const isLoggedIn = appStore.authStatus === AuthStatus.LoggedIn;
  const urlParams = new URLSearchParams(window.location.search);
  const domain = urlParams.get("domain");
  const redirectUrl = urlParams.get("redirectUrl");
  if (isLoggedIn && domain === "docs") {
    const token = await rollupClient.auth.getDocsToken();
    if (token) {
      window.location.href = `https://docs.rollup.ai/?jwt=${token}`;
      return null;
    }
  }
  let destination = isLoggedIn ? `/organizations` : "/login";
  if (domain) {
    destination += `?domain=${domain}`;
  }
  if (redirectUrl) {
    destination += `${domain ? "&" : "?"}redirectUrl=${redirectUrl}`;
  }
  return redirect(destination);
}

function AppIndex() {
  return <Loading />;
}

export { appIndexLoader };
export default AppIndex;
