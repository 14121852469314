import { useEffect, useRef, useState } from "react";
import { Button } from "@blueprintjs/core";

import Avatar, { AvatarShape, AvatarSize } from "src/components/Avatar";
import UploadFileButton from "src/components/UploadFileButton";

import "./AvatarInput.scss";

type Props = {
  file?: File;
  text?: string;
  isAvatar?: boolean;
  setFile: (file?: File) => void;
  onPreviewChange?: (url: string) => void;
};

const AvatarInput = (props: Props) => {
  const { file, setFile, text, isAvatar, onPreviewChange } = props;
  const [imgSrc, setImgSrc] = useState("");
  const imgInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onPreviewChange) {
      onPreviewChange(imgSrc);
    }
  }, [onPreviewChange, imgSrc]);

  useEffect(() => {
    if (!file) {
      setImgSrc("");
    } else {
      const objectUrl = URL.createObjectURL(file);
      setImgSrc(objectUrl);
    }

    return () => URL.revokeObjectURL(imgSrc);
  }, [file]);

  const handleDelete = () => {
    setImgSrc("");
    setFile(undefined);
    if (imgInputRef.current) {
      imgInputRef.current.value = "";
    }
  };

  return (
    <div className="avatar-input">
      <Avatar imgUrl={imgSrc} letter="S" size={AvatarSize.Large} shape={isAvatar ? AvatarShape.Circle : AvatarShape.Square} />
      <UploadFileButton inputRef={imgInputRef} onChange={files => setFile(files[0])} outlined large text={text || "Upload new logo"} />
      {imgSrc && (
        <Button onClick={handleDelete} large minimal icon="trash">
          Delete logo
        </Button>
      )}
    </div>
  );
};

export default AvatarInput;
