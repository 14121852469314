import { Outlet } from "react-router-dom";
import { Button, Divider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import rollup_dark from "src/assets/img/Logo_Dark.svg";
import rollup_light from "src/assets/img/Logo_Light.svg";
import Bar from "src/components/Bar";
import FeedbackDialog from "src/components/FeedbackDialog";
import Footer from "src/components/Footer";
import appStore from "src/store/AppStore";

import AppWrapper from "../../AppWrapper";

import "./AppLayout.scss";

const RightHeaderElements = observer(() => {
  return (
    <div className="bar-right flex gap-2 pr-2">
      <Button large onClick={appStore.env?.toggleTheme} minimal icon={appStore.env?.themeIsDark ? "flash" : "moon"} />
      <Button outlined large onClick={appStore.logout}>
        Logout
      </Button>
    </div>
  );
});

const LeftHeaderElements = observer(() => {
  return (
    <div className="bar-left">
      <img height={20} alt="Rollup logo" src={appStore.env?.themeIsDark ? rollup_dark : rollup_light} />
    </div>
  );
});

/** Main function. */
function AppLayout() {
  return (
    <AppWrapper>
      <FeedbackDialog />
      <div id="app" tabIndex={0} className="app-layout">
        <Bar className="bar-content">
          <LeftHeaderElements />
          <RightHeaderElements />
        </Bar>
        {/* 2. Main Content */}
        <Divider className="m-0" />
        <Outlet />
        <Footer />
      </div>
    </AppWrapper>
  );
}

/** Exports. */
export default observer(AppLayout);
