import { AnalyticsBrowser } from "@segment/analytics-next";

const SEGMENT_WRITE_KEY = import.meta.env.VITE_SEGMENT_WRITE_KEY;

export const analytics = new AnalyticsBrowser();

export function initSegment() {
  if (import.meta.env.VITE_ENV_TYPE !== "production" && import.meta.env.VITE_ENV_TYPE !== "staging") {
    console.debug("Skipping Segment init in non-production environment");
    return;
  }

  if (!SEGMENT_WRITE_KEY) {
    console.warn("Missing VITE_SEGMENT_WRITE_KEY environment variable");
    return;
  }

  analytics.load({ writeKey: SEGMENT_WRITE_KEY });
}
