import { RefObject } from "react";
import { Button, IconName, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";

import useFileUploadMessage from "src/hooks/useFileUploadMessage";
import { validateFiles } from "src/utilities/file";

import "./UploadFileButton.scss";

const ONE_MB_IN_BYTES = 1024 * 1024;
const ALLOWED_FILE_UPLOAD_SIZE = ONE_MB_IN_BYTES * 10;
const ALLOWED_FILE_FORMATS = ["image/png", "image/jpeg"];

type TUploadFileButtonProps = {
  onChange?: (file: FileList) => void;
  text?: string;
  active?: boolean;
  multiple?: boolean;
  outlined?: boolean;
  icon?: IconName;
  className?: string;
  large?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
};

export const UploadFileButton = (props: TUploadFileButtonProps) => {
  const { inputRef, outlined, text, onChange, icon, active, multiple, className, large } = props;
  const { showSizeErrorMessage, showFormatErrorMessage } = useFileUploadMessage();

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.length) {
      const allowedFiles = validateFiles(
        e.currentTarget?.files,
        ALLOWED_FILE_FORMATS,
        ALLOWED_FILE_UPLOAD_SIZE,
        showSizeErrorMessage,
        showFormatErrorMessage
      );
      onChange?.(allowedFiles);
    }
  };

  return (
    <Tooltip content="Upload attachment" position="top" hoverOpenDelay={400}>
      <label className="upload-file-button">
        <input ref={inputRef} multiple={multiple} className="upload-file-button--input" type="file" onChange={handleFileInputChange} />
        <Button
          outlined={outlined}
          large={large}
          active={active}
          className={classNames("upload-file-button--button", className)}
          minimal
          alignText="center"
          icon={icon || "media"}
        >
          {text ?? "Add Attachment"}
        </Button>
      </label>
    </Tooltip>
  );
};

export default UploadFileButton;
