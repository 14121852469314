import { AnchorButton, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import Footer from "src/components/Footer";
import SidebarPlaceholder from "src/components/SidebarPlaceholder";
import Title, { TitleSize } from "src/components/Title";

type Props = {
  email?: string;
  name?: string;
};

const Finish = (props: Props) => {
  const { email, name } = props;
  let link = "https://cal.com/lorin-shamos-3rebdf/30min?";

  if (email) {
    link += `email=${email}`;
  }

  if (name) {
    link += `&name=${name}`;
  }

  return (
    <>
      <div className="onboarding--content">
        <div className="onboarding--form">
          <Title>Great start!</Title>
          <Title size={TitleSize.H4}>We get back to you within 24 hours on weekdays to approve your organization.</Title>
          <AnchorButton href={link} target="_blank" fill large intent={Intent.PRIMARY}>
            Book a discovery call
          </AnchorButton>
        </div>
        <Footer />
      </div>
      <div className="onboarding--sidebar">
        <SidebarPlaceholder />
      </div>
    </>
  );
};

export default observer(Finish);
