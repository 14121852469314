import { ReactNode } from "react";
import classNames from "classnames";

import "./Title.scss";

export enum TitleSize {
  H5 = "h5",
  H4 = "h4",
  H3 = "h3",
  H2 = "h2",
  H1 = "h1",
  Caption = "caption",
}

type Props = {
  children: ReactNode;
  className?: string;
  size?: TitleSize;
};

export const Title = (props: Props) => {
  const { children, className, size = TitleSize.H1 } = props;

  return <div className={classNames("title", `title--${size}`, className)}>{children}</div>;
};

export default Title;
