import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";

import rollup_dark from "src/assets/img/Logo_Dark.svg?url";
import rollup_light from "src/assets/img/Logo_Light.svg?url";
import appStore from "src/store/AppStore";

import "./LoginHeader.scss";

const LoginHeader = () => {
  return (
    <div className="login-header">
      <a href="https://rollup.ai/">
        <img height={35} alt="Rollup logo" src={appStore.env?.themeIsDark ? rollup_dark : rollup_light} />
      </a>
      <Button
        className="login-header--theme"
        large
        onClick={appStore.env?.toggleTheme}
        minimal
        icon={appStore.env?.themeIsDark ? "flash" : "moon"}
      />
    </div>
  );
};

export default observer(LoginHeader);
