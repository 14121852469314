import classNames from "classnames";

import "./StepsIndicator.scss";

type Props = {
  amount: number;
  current: number;
};

const StepsIndicator = (props: Props) => {
  const { amount, current } = props;

  return (
    <div className="steps-indicator">
      {Array.from({ length: amount }, (_, i) => {
        const isActive = i === current;
        const disabled = i > current;
        const className = classNames("steps-indicator--step", {
          ["steps-indicator--step-active"]: isActive,
          ["steps-indicator--step-disabled"]: disabled,
        });
        return <div key={i} className={className} />;
      })}
    </div>
  );
};

export default StepsIndicator;
