import { Auth } from "./auth";
import { ParentClient } from "./client";
import { Organizations } from "./organizations";
import { Profiles } from "./profiles";
import { StytchAuth } from "./stytchAuth";

class RollupClient implements ParentClient {
  public readonly apiPrefix = "/core";
  public readonly auth: Auth;
  public readonly stytchAuth: StytchAuth;
  public readonly organizations: Organizations;
  public readonly profiles: Profiles;

  constructor(public readonly url: string) {
    this.auth = new Auth(this);
    this.stytchAuth = new StytchAuth({ ...this, apiPrefix: "" });
    this.organizations = new Organizations(this);
    this.profiles = new Profiles(this);
  }
}

export default RollupClient;
