import { types } from "mobx-state-tree";

const AppUiStore = types
  .model("AppUi", {
    signUpDialogEnabled: types.optional(types.boolean, false),
  })
  .actions(self => ({
    showSignUpDialog() {
      self.signUpDialogEnabled = true;
    },
    hideSignUpDialog() {
      self.signUpDialogEnabled = false;
    },
  }));

export default AppUiStore;
