import { ReactElement } from "react";
import { Classes, Icon } from "@blueprintjs/core";
import classNames from "classnames";

import "./CustomIcon.scss";

export const CustomIcon = (props: { icon: ReactElement; large?: boolean }) => {
  const className = classNames(Classes.ICON, "custom-icon", { large: props.large });
  return <Icon icon={<div className={className}>{props.icon}</div>} />;
};
