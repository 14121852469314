import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";

import Footer from "src/components/Footer";
import OrganizationsList from "src/components/OrganizationsList";
import SidebarPlaceholder from "src/components/SidebarPlaceholder";
import StepsIndicator from "src/components/StepsIndicator";
import Title, { TitleSize } from "src/components/Title";
import appStore from "src/store/AppStore";

import { ONBOARDING_STEPS, OnboardingStep } from "../Onboarding";

type Props = {
  currentStep: OnboardingStep;
  setStep: (step: OnboardingStep) => void;
};

const OrganizationData = (props: Props) => {
  const { setStep, currentStep } = props;

  return (
    <>
      <div className="onboarding--content">
        <div className="onboarding--form">
          <StepsIndicator current={currentStep} amount={ONBOARDING_STEPS} />
          <div>
            <Title>Your organizations</Title>
            <Title size={TitleSize.H4}>Select existing or request to create new one</Title>
          </div>
          {!!appStore.organizations.length && <OrganizationsList hideTitle vertical organizations={appStore.organizations} />}
          <Button outlined icon="add" onClick={() => setStep(OnboardingStep.CreateOrg)} large fill>
            Create Organization
          </Button>
          <Button onClick={() => setStep(OnboardingStep.Theme)} large minimal fill icon="chevron-left">
            Back
          </Button>
        </div>
        <Footer />
      </div>
      <div className="onboarding--sidebar">
        <SidebarPlaceholder />
      </div>
    </>
  );
};

export default observer(OrganizationData);
