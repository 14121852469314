import { Pages } from "src/router/elements/types";

export const getOrganizationDestinationUrl = (slug = "", redirectUrl = "", forceUpdateAccess?: boolean) => {
  let destination = import.meta.env.VITE_HOST_URL.replace("{{slug}}", slug);

  if (redirectUrl) {
    destination += redirectUrl.startsWith("/") ? redirectUrl : `/${redirectUrl}`;
  }

  if (forceUpdateAccess) {
    destination += `${destination.includes("?") ? "&" : "?"}forceUpdateAccess=true`;
  }

  return destination;
};

export const isInternalRoute = (route: string): route is Pages => {
  return Object.values(Pages).includes(route as Pages);
};

export const getFirstPageFromPath = (path: string): string => {
  return (
    path
      .split("/")
      .filter(s => !!s)
      .at(0) || ""
  );
};
