import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import { datadogRum } from "@datadog/browser-rum";
import { observer } from "mobx-react";

import { rollupClient } from "src/api";
import { OrgData, UserData } from "src/api/auth/types";
import { showToast } from "src/components/UiLayers/toaster";
import appStore from "src/store/AppStore";

import Loading from "../Loading";

import Finish from "./Steps/Finish";
import Organizations from "./Steps/Organizations";
import OrgDataStep from "./Steps/OrgData";
import Theme from "./Steps/Theme";
import UserDataStep from "./Steps/UserData";

import "./Onboarding.scss";

export enum OnboardingStep {
  UserData,
  Theme,
  Organizations,
  CreateOrg,
  Finish,
}

const defaultUserData = {
  name: "",
  email: "",
  role: "",
  department: "",
};

const defaultOrgData = {
  kind: "",
  name: "",
  slug: "",
};

export const ONBOARDING_STEPS = 4;

/** Main function. */
function Onboarding() {
  const [step, setStep] = useState(OnboardingStep.UserData);
  const [userData, setUserData] = useState<UserData>(defaultUserData);
  const [orgData, setOrgData] = useState<OrgData>(defaultOrgData);
  const [loading, setLoading] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUserData({ ...userData, email: appStore.email });
    if (appStore.email && !isFetchingUser) {
      setIsFetchingUser(true);
      rollupClient.auth
        .getPendingUser(appStore.email)
        .then(userExists => {
          userExists && setStep(OnboardingStep.Finish);
        })
        .catch(() => {
          datadogRum.addError("Error getting user", { email: appStore.email });
          showToast("Error getting user", Intent.DANGER);
        })
        .finally(() => {
          setIsFetchingUser(false);
        });
    }
  }, [appStore.email]);

  useEffect(() => {
    if (!appStore.token && !appStore.fetchingOrganizations) {
      navigate("/");
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    rollupClient.auth
      .signUp({ userData, orgData })
      .then(() => {
        setStep(OnboardingStep.Finish);
      })
      .catch(err => {
        const errorMessage = err.response?.data?.message || "Error submitting data";
        datadogRum.addError(errorMessage, { userData, orgData });
        showToast(errorMessage, Intent.DANGER);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderStep = () => {
    switch (step) {
      case OnboardingStep.UserData:
        return <UserDataStep currentStep={step} userData={userData} setUserData={setUserData} setStep={setStep} />;
      case OnboardingStep.Theme:
        return <Theme currentStep={step} setStep={setStep} />;
      case OnboardingStep.Organizations:
        return <Organizations currentStep={step} setStep={setStep} />;
      case OnboardingStep.CreateOrg:
        return (
          <OrgDataStep
            setStep={setStep}
            loading={loading}
            currentStep={step}
            orgData={orgData}
            setOrgData={setOrgData}
            handleSubmit={handleSubmit}
          />
        );
      case OnboardingStep.Finish:
        return <Finish email={userData.email} name={userData.name} />;
    }
  };

  return <div className="onboarding">{isFetchingUser ? <Loading /> : renderStep()}</div>;
}

/** Exports. */
export default observer(Onboarding);
