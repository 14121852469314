import { useState } from "react";
import { Button, Checkbox, InputGroup, Intent } from "@blueprintjs/core";

import PasswordInput from "src/components/PasswordInput";
import Select from "src/components/Select";
import useForm from "src/hooks/useForm";

import "../Login/Login.scss";

enum EInviteStep {
  NAME,
  PASSWORD,
  META,
  SUCCESS,
}

const TERMS_LINK = "";

const PASSWORD_MIN_LENGTH = 8;

/** Main function. */
function Invite() {
  const [step, setStep] = useState<EInviteStep>(EInviteStep.NAME);
  const [used, setUsed] = useState("");
  const [role, setRole] = useState("");
  const { password, setPassword, onNameChange, toggleTerms, name, terms } = useForm();

  const handleClickDiscover = () => {
    console.log("handleClickDiscover");
  };

  const renderNameStep = () => {
    return (
      <>
        <div className="login-form--divide">To start, we need some information from you.</div>
        <InputGroup fill large type="text" required placeholder="What is your name?" onChange={onNameChange} value={name} />
        <Button disabled={!name} onClick={() => setStep(EInviteStep.PASSWORD)} fill intent={Intent.PRIMARY} large>
          Continue
        </Button>
      </>
    );
  };

  const renderPasswordStep = () => {
    return (
      <>
        <div className="login-form--divide">Now set your password</div>
        <PasswordInput minLength={PASSWORD_MIN_LENGTH} placeholder="Password 8+ characters" onChange={setPassword} password={password} />
        <div className="login-form--checkbox">
          <Checkbox checked={terms} onChange={toggleTerms} />
          <span>
            I agree to{" "}
            <a rel="noreferrer" target="_blank" href={TERMS_LINK}>
              Rollup terms & conditions
            </a>
          </span>
        </div>
        <Button
          disabled={password.length < PASSWORD_MIN_LENGTH || !terms}
          onClick={() => setStep(EInviteStep.META)}
          fill
          intent={Intent.PRIMARY}
          large
        >
          Continue
        </Button>
        <Button icon="chevron-left" onClick={() => setStep(EInviteStep.NAME)} large minimal fill>
          Back
        </Button>
      </>
    );
  };

  const renderMetaStep = () => {
    return (
      <>
        <div className="login-form--divide">Please answer 2 quick questions and help us tailor your experience to your needs</div>
        <Select selectedItem={role} items={["eng", "des"]} onSelect={setRole} label="What is your role?" />
        <Select selectedItem={used} items={["yes", "no"]} onSelect={setUsed} label="Have you ever used engineer tools?" />
        <Button disabled={!name} onClick={() => setStep(EInviteStep.SUCCESS)} fill intent={Intent.PRIMARY} large rightIcon="chevron-right">
          Setup and Continue
        </Button>
        <Button icon="chevron-left" onClick={() => setStep(EInviteStep.PASSWORD)} large minimal fill>
          Back
        </Button>
      </>
    );
  };

  const renderSuccessStep = () => {
    return (
      <>
        <div className="login-form--divide">Let’s discover Rollup system. We will show you lots of great things.</div>
        <Button onClick={handleClickDiscover} fill intent={Intent.PRIMARY} large>
          Start discover
        </Button>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case EInviteStep.PASSWORD:
        return renderPasswordStep();
      case EInviteStep.META:
        return renderMetaStep();
      case EInviteStep.SUCCESS:
        return renderSuccessStep();
      default:
        return renderNameStep();
    }
  };

  return (
    <div className="login">
      <div className="login-form">
        <h1 className="login-form--title">{step === EInviteStep.SUCCESS ? "🥳 Great start!" : "Welcome to Rollup"}</h1>
        {renderStep()}
      </div>
    </div>
  );
}

/** Exports. */
export default Invite;
