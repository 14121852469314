import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { observer } from "mobx-react";

import appStore, { AuthStatus } from "src/store/AppStore";

import { appIndexLoader } from "./elements/AppIndex";
import * as Element from "./elements";

/** Main function. */
function AppRouter() {
  const isPending = appStore.authStatus === AuthStatus.Pending;
  const isFetchingProfile = appStore.authStatus === AuthStatus.FetchingProfile;
  const isLoggedIn = appStore.authStatus === AuthStatus.LoggedIn;
  const isLoggedOut = appStore.authStatus === AuthStatus.LoggedOut;
  const isLoading = isPending || isFetchingProfile;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {isLoading && <Route path="*" element={<Element.Loading />} />}
        {isLoggedOut && (
          <Route element={<Element.AuthAppLayout />}>
            <Route path="*" element={<Element.Login />} />
            <Route path="signup" element={<Element.SignUp />} />
            <Route path="invite" element={<Element.Invite />} />
            <Route path="onboarding" element={<Element.Onboarding />} />
            <Route path="organizations" element={<Element.Organizations />} />
            <Route path="forgot-password" element={<Element.PasswordRecovery />} />
            <Route path="reset-password" element={<Element.NewPassword />} />
          </Route>
        )}
        {isLoggedIn && (
          <Route element={<Element.AppLayout />}>
            <Route index element={<Element.AppIndex />} loader={appIndexLoader} />
            <Route path="*" element={<Element.AllSet />} />
            <Route path="logout" element={<Element.Logout />} />
            <Route path="login" element={<Navigate to="/" />} />
            <Route path="signup" element={<Navigate to="/" />} />
          </Route>
        )}
      </>
    )
  );

  return <RouterProvider router={router} />;
}

/** Exports. */
export default observer(AppRouter);
