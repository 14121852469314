import { HTMLProps } from "react";
import classNames from "classnames";

import "./Bar.scss";

type BarProps = HTMLProps<HTMLDivElement>;

export const Bar = ({ className, ...rest }: BarProps) => {
  return <div className={classNames("bar-container", className)} {...rest} />;
};
