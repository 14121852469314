import { useEffect } from "react";
import { Classes } from "@blueprintjs/core";

import appStore from "src/store/AppStore";

// ${NS}-dark is the class name for the dark theme in BlueprintJS. Kept for compatibility.
const darkClasses = [Classes.DARK];

//Default is set to undefined/null - it allows use of 'window.matchMedia' to detect the user's system theme if not picked by the user
export const useThemeSwitcher = () => {
  useEffect(() => {
    if (appStore.env?.themeIsDark) {
      document.body.classList.add(...darkClasses);
    } else {
      document.body.classList.remove(...darkClasses);
    }
  }, [appStore.env?.themeIsDark]);
};
