import { Intent } from "@blueprintjs/core";
import { datadogRum } from "@datadog/browser-rum";
import { Instance, types } from "mobx-state-tree";

import { EFeedbackType } from "src/components/FeedbackDialog/types";
import { showToast } from "src/components/UiLayers/toaster";

import { IBebSession, ISessionData } from "../lib/BirdEatsBug/types";

export const FeedbackStore = types
  .model("Feedback", {
    id: types.optional(types.identifier, ""),
    collectionId: types.optional(types.string, EFeedbackType.PROBLEM),
    loading: types.optional(types.boolean, false),
    visible: types.optional(types.boolean, false),
  })
  .actions(self => {
    return {
      setLoading(loading: boolean) {
        self.loading = loading;
      },
      setCollectionId(collectionId: string) {
        self.collectionId = collectionId;
      },
      hide() {
        self.visible = false;
      },
      show() {
        self.visible = true;
      },
    };
  })
  .actions(self => {
    return {
      uploadSessionData(uploaderEmail: string, title: string, description: string) {
        self.hide();
        showToast("We are uploading your feedback");
        try {
          const createdSessionPromise = window.birdeatsbug?.createSessionIfRequired();
          createdSessionPromise?.then((createdSession: IBebSession) => {
            const session = {
              ...createdSession,
              collectionId: self.collectionId,
              uploaderEmail,
              title,
              description,
            };

            const sessionData: ISessionData = {
              session,
              events: [],
              domEvents: null,
              networkRequests: null,
            };

            window.birdeatsbug?.uploadSession(sessionData).then(() => {
              showToast("Feedback successfully uploaded!", Intent.SUCCESS);
            });
          });
        } catch (err: any) {
          datadogRum.addError(err);
          showToast("Error sending feedback", Intent.DANGER);
        }
      },
    };
  });

export interface IFeedbackStore extends Instance<typeof FeedbackStore> {}
