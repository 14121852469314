import { PropsWithChildren } from "react";
import { observer } from "mobx-react";

import ErrorBoundary from "src/components/ErrorBoundary";

/** Main function. */
function AppWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <ErrorBoundary logErrors>
      <>{children}</>
    </ErrorBoundary>
  );
}

/** Exports. */
export default observer(AppWrapper);
