import { useEffect } from "react";
import { observer } from "mobx-react";

import appStore from "src/store/AppStore";

function Logout() {
  useEffect(() => {
    appStore.logout();
  }, []);

  return null;
}

export default observer(Logout);
