import { UserOrganization } from "src/models/organizations";

import { HttpClient, ParentClient } from "../client";

export class Organizations extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint: string = "/organizations";

  public getOrganizations = () => {
    return this.instance.get<UserOrganization[]>(`${this.endpoint}`);
  };

  public getUserOrg = () => {
    return this.instance.get<UserOrganization>(`${this.endpoint}/current`);
  };

  public getOrganizationLink = (orgId: string, token: string, redirectUrl?: string): string => {
    let destination = `${this.parent.url}/organization/set_active/${orgId}?token=${token}`;

    if (redirectUrl) {
      destination += `&redirectUrl=${redirectUrl}`;
    }

    return destination;
  };
}
