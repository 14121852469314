import React, { useEffect, useRef } from "react";
import { Icon, Tooltip } from "@blueprintjs/core";

import "./OrganizationInput.scss";

type Props = {
  onChange?: (value: string) => void;
  onEnter?: () => void;
  defaultValue?: string;
  label?: string;
  hideNotice?: boolean;
  disabled?: boolean;
};

const OrganizationInput = (props: Props) => {
  const { onChange, defaultValue, onEnter, hideNotice, label, disabled } = props;
  const editableDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue !== undefined && editableDiv.current) {
      editableDiv.current.innerText = defaultValue;
    }
  }, [defaultValue]);

  const handleSlugChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLElement).innerText;
    onChange?.(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onEnter?.();
    }
  };

  return (
    <div className="organization-input--wrap">
      {label && <div>{label}</div>}
      <div
        ref={editableDiv}
        className="organization-input"
        role="textbox"
        onKeyDown={handleKeyDown}
        onInput={handleSlugChange}
        suppressContentEditableWarning
        data-testid="slug-input"
        contentEditable={!disabled}
      />
      {!hideNotice && (
        <div>
          <Tooltip
            fill
            usePortal={false}
            position="top"
            content="Rollup doesn't support discovery flow for users that are using password authentication"
          >
            <div className="login-form--info">
              <Icon icon="info-sign" />I don't know my Organization ID
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default OrganizationInput;
