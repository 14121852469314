import { Popover } from "@blueprintjs/core";

import packageInfo from "src/../package.json";

import InfoMenu from "./InfoMenu";

import "./InfoMenuButton.scss";

const InfoMenuButton = () => {
  return (
    <div>
      <Popover usePortal={false} position="top-right" content={<InfoMenu />} minimal>
        <div className="info-menu-button">
          Version <code>{packageInfo.version}</code>
        </div>
      </Popover>
    </div>
  );
};

export default InfoMenuButton;
