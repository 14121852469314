import { Classes, Menu, MenuItem } from "@blueprintjs/core";
import { observer } from "mobx-react";
import moment from "moment";

import packageInfo from "src/../package.json";
import gitInfo from "src/assets/git-info.json";
import { showToast } from "src/components/UiLayers/toaster";
import { copyToClipboard } from "src/utilities/string";

const InfoMenu = () => {
  const buildDate = moment(gitInfo.datetime)?.format("MMM Do YYYY") ?? "";

  const handleClicked = async (text: string) => {
    try {
      await copyToClipboard(text);
      showToast("Copied info to clipboard", "success", "info-sign");
    } catch (err) {
      return;
    }
  };

  return (
    <Menu className={Classes.RUNNING_TEXT}>
      <MenuItem
        onClick={() => handleClicked(`Version: ${packageInfo.version}`)}
        text={
          <>
            Version <code>{packageInfo.version}</code>
          </>
        }
      />
      <MenuItem
        onClick={() => handleClicked(`Build date: ${buildDate}`)}
        text={
          <>
            Build date <code>{buildDate}</code>
          </>
        }
      />
      <MenuItem
        text={
          <>
            Commit <code>{gitInfo.shortHash}</code>
          </>
        }
        onClick={() => handleClicked(`Commit: ${gitInfo.shortHash}`)}
      />
    </Menu>
  );
};

export default observer(InfoMenu);
