import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Intent } from "@blueprintjs/core";

import EmailInput from "src/components/EmailInput";
import OrganizationInput from "src/components/OrganizationInput";
import useForm from "src/hooks/useForm";
import useSearchParam from "src/hooks/useSearchParam";
import appStore from "src/store/AppStore";
import { isValid } from "src/utilities/email";

import "./Login.scss";

enum ESubmitState {
  pending,
  loading,
  sent,
}

/** Main function. */
function PasswordRecovery() {
  const [slug, setSlug] = useState("");
  const { setEmail, email } = useForm();
  const [domain] = useSearchParam("domain");
  const [status, setStatus] = useState(ESubmitState.pending);
  const navigate = useNavigate();

  const loading = status === ESubmitState.loading;
  const disabled = !isValid(email) || loading || !slug;

  const handlePassRecovery = () => {
    if (!disabled) {
      appStore.resetPasswordByEmail(email, slug);
      setStatus(ESubmitState.sent);
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  const renderSentState = () => (
    <>
      <h1 className="login-form--title">Reset your password</h1>
      <div className="login-form--divide">
        If an account exists for {email} we’ll send instructions for resetting your password. Didn’t get them? Check the email address or
        ask to resend the instructions.
      </div>
      <Button onClick={handleBack} disabled={!isValid(email) || loading} large intent={Intent.PRIMARY} fill>
        Back to Sign in
      </Button>
      <Button onClick={handlePassRecovery} large minimal fill>
        Resend the instruction again
      </Button>
    </>
  );

  const renderPendingState = () => (
    <>
      <h1 className="login-form--title">Password recovery</h1>
      <OrganizationInput onEnter={handlePassRecovery} defaultValue={domain} onChange={setSlug} />
      <div className="login-form--divide">Enter the email you use for rollup.ai</div>
      <EmailInput onEnter={handlePassRecovery} email={email} onChange={setEmail} required />
      <Button onClick={handlePassRecovery} disabled={disabled} large intent={Intent.PRIMARY} fill>
        Continue
      </Button>
      <Button icon="arrow-left" onClick={handleBack} large minimal fill>
        Back to Sign in
      </Button>
    </>
  );

  return (
    <div className="login">
      <div className="login-wrap">
        <div className="login-form">{status === ESubmitState.pending ? renderPendingState() : renderSentState()}</div>
      </div>
    </div>
  );
}

/** Exports. */
export default PasswordRecovery;
