export const validateFiles = (
  files: FileList,
  allowedFormats: string[],
  allowedSize: number,
  onSizeError: (file: File, size: number) => void,
  onFormatError: (file: File) => void
): FileList => {
  const allowedFiles = new DataTransfer();

  Array.from(files).forEach((file: File) => {
    let isValidFormat = true;
    let isValidSize = true;

    if (allowedFormats.length) {
      const format = file.name.split(".").pop();
      if (!format || !allowedFormats.join(", ").includes(format.toLowerCase())) {
        isValidFormat = false;
        onFormatError(file);
      }
    }

    if (file.size > allowedSize) {
      isValidSize = false;
      onSizeError(file, allowedSize);
    }

    if (isValidFormat && isValidSize) {
      allowedFiles.items.add(file);
    }
  });

  return allowedFiles.files;
};

export function formatFileSize(sizeInBytes: number): string {
  if (!isFinite(sizeInBytes)) {
    return "";
  }
  if (sizeInBytes >= 1e12) {
    return `${(sizeInBytes / 1e12).toFixed(2)} TB`;
  } else if (sizeInBytes >= 1e9) {
    return `${(sizeInBytes / 1e9).toFixed(1)} GB`;
  } else if (sizeInBytes >= 1e6) {
    return `${(sizeInBytes / 1e6).toFixed(1)} MB`;
  } else if (sizeInBytes >= 1e3) {
    return `${(sizeInBytes / 1e3).toFixed(1)} kB`;
  } else {
    return `${sizeInBytes} B`;
  }
}
