import { useEffect, useState } from "react";
import { AnchorButton, NonIdealState } from "@blueprintjs/core";
import classNames from "classnames";
import Cookies from "js-cookie";

import { rollupClient } from "src/api";
import useSearchParam from "src/hooks/useSearchParam";
import { MembershipType, Organization, Organizations, OrganizationStatus } from "src/models/organizations";
import appStore from "src/store/AppStore";

import Avatar, { AvatarShape, AvatarSize } from "../Avatar";

import "./OrganizationsList.scss";

type TOrganizationsListProps = {
  organizations: Organizations;
  vertical?: boolean;
  hideTitle?: boolean;
};

const OrganizationsList = (props: TOrganizationsListProps) => {
  const { hideTitle, organizations, vertical } = props;
  const [error, setError] = useState(false);
  const orgSlug = Cookies.get("org_slug");
  const [domain] = useSearchParam("domain");
  const [redirectUrl] = useSearchParam("redirectUrl");

  useEffect(() => {
    if ((orgSlug || domain) && organizations.length) {
      console.log(organizations);
      const availableOrg = organizations.find(o => o.organization.organization_slug === (orgSlug || domain));
      setError(!availableOrg);
      Cookies.remove("org_slug");
      if (availableOrg) {
        window.location.href = rollupClient.organizations.getOrganizationLink(availableOrg.organization.organization_id, appStore.token);
      }
    }
  }, []);

  const orgButtonText = (membership: MembershipType, pending?: boolean) => {
    if (pending) {
      return "Confirmation pending";
    }

    return membership === MembershipType.Eligible ? "Join" : "Open";
  };

  const renderOrganization = (accessedOrg: Organization) => {
    const { organization, membership } = accessedOrg;
    const href = rollupClient.organizations.getOrganizationLink(organization.organization_id, appStore.token, redirectUrl);
    const orgPending = organization.trusted_metadata?.status === OrganizationStatus.Pending;

    return (
      <div className="organizations-list--item" key={organization.organization_id}>
        <div className="organizations-list--item-data">
          <Avatar
            className="organizations-list--avatar"
            size={AvatarSize.Large}
            letter={organization.organization_name.at(0)}
            imgUrl={organization.organization_logo_url}
            shape={AvatarShape.Square}
          />
          <h3 className="organizations-list--item-title">{organization.organization_name}</h3>
        </div>
        <AnchorButton disabled={orgPending} intent="primary" href={href} className="organizations-list--button">
          {orgButtonText(membership.type, orgPending)}
        </AnchorButton>
      </div>
    );
  };

  const existingOrganizations = organizations.filter(o => o.membership.type !== MembershipType.Eligible);
  const eligibleOrganizations = organizations.filter(o => o.membership.type === MembershipType.Eligible);

  return (
    <div className={classNames("organizations-list--wrap", { ["organizations-list--vertical"]: vertical })}>
      {!existingOrganizations.length && !eligibleOrganizations.length ? (
        <NonIdealState icon="error" title="No available organizations" />
      ) : null}
      {error && (
        <div className="organizations-list">
          <h1 className="organizations-list--title organizations-list--err">You don't have an access to the selected org</h1>
        </div>
      )}
      {existingOrganizations.length ? (
        <div className="organizations-list">
          {!hideTitle && <h1 className="organizations-list--title">Your organizations</h1>}
          {existingOrganizations.map(renderOrganization)}
        </div>
      ) : null}
      {eligibleOrganizations.length ? (
        <div className="organizations-list">
          <h1 className="organizations-list--title">Organizations connected to your email</h1>
          {eligibleOrganizations.map(renderOrganization)}
        </div>
      ) : null}
    </div>
  );
};

export default OrganizationsList;
