import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore from "src/store/AppStore";

import HelpMenuButton from "../InfoMenu/InfoMenuButton";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <Button onClick={appStore.feedback.show} icon="help" large minimal>
        Help
      </Button>
      <HelpMenuButton />
    </div>
  );
};

export default observer(Footer);
