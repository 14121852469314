import { useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { UserData } from "src/api/auth/types";
import Avatar, { AvatarSize } from "src/components/Avatar/Avatar";
import AvatarInput from "src/components/AvatarInput";
import Footer from "src/components/Footer";
import Input from "src/components/Input";
import StepsIndicator from "src/components/StepsIndicator";
import Title, { TitleSize } from "src/components/Title";

import { ONBOARDING_STEPS, OnboardingStep } from "../Onboarding";

type Props = {
  setStep: (step: OnboardingStep) => void;
  userData: UserData;
  currentStep: OnboardingStep;
  setUserData: (userData: UserData) => void;
};

const UserDataStep = (props: Props) => {
  const { currentStep, setStep, userData, setUserData } = props;
  const [avatarPreview, setAvatarPreview] = useState<string>("");
  const nextButtonDisabled = !userData.name || !userData.role;

  return (
    <>
      <div className="onboarding--content">
        <div className="onboarding--form">
          <StepsIndicator current={currentStep} amount={ONBOARDING_STEPS} />
          <Title>Welcome to Rollup</Title>
          <Title size={TitleSize.H4}>To start, we need some information from you.</Title>
          <AvatarInput
            onPreviewChange={setAvatarPreview}
            isAvatar
            text="Upload profile photo"
            file={userData.avatar}
            setFile={avatar => setUserData({ ...userData, avatar })}
          />
          <Input label="Name" value={userData.name} onChange={name => setUserData({ ...userData, name })} />
          <Input label="What is your role?" value={userData.role} onChange={role => setUserData({ ...userData, role })} />
          <Input
            label="What is your department?"
            value={userData.department}
            onChange={department => setUserData({ ...userData, department })}
          />
          <Button disabled={nextButtonDisabled} onClick={() => setStep(OnboardingStep.Theme)} large fill intent={Intent.PRIMARY}>
            Continue
          </Button>
        </div>
        <Footer />
      </div>
      <div className="onboarding--sidebar">
        <div className="sidebar-placeholder--content">
          <Title size={TitleSize.H2}>Let’s make your profile look finished and informative for your colleagues</Title>
          <Title size={TitleSize.H4}>How your profile will appear:</Title>
          <div className="onboarding--profile-preview">
            <Avatar letter={userData.name.at(0) || "S"} size={AvatarSize.Large} imgUrl={avatarPreview} />
            <div>
              <Title size={TitleSize.H3}>{userData.name || "Steven Smith"}</Title>
              <Title size={TitleSize.Caption}>{userData.role || "Your role"}</Title>
            </div>
          </div>
          <div className="onboarding--profile-preview-sm">
            <Avatar isOnline letter={userData.name.at(0) || "S"} size={AvatarSize.Small} imgUrl={avatarPreview} />
            {userData.name || "Steven Smith"}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(UserDataStep);
