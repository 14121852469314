import { createRoot } from "react-dom/client";
import { IconName, Intent, OverlayToaster, Position } from "@blueprintjs/core";

const DEFAULT_TIMEOUT_ERROR = 2000;
const DEFAULT_TIMEOUT_INFO = 1000;

/** Singleton toaster instance. Create separate instances for different propertyAutocompleteOptions. */
export let AppToaster: OverlayToaster;

const toasterElement = document.getElementById("toaster")!;
const toasterRoot = createRoot(toasterElement);

toasterRoot.render(
  <OverlayToaster
    ref={instance => {
      if (instance) {
        AppToaster = instance;
      }
    }}
    className="recipe-toaster"
    position={Position.BOTTOM_LEFT}
    maxToasts={5}
  />
);

const displayToast = (message: string, intent: Intent, icon?: IconName, timeout?: number) => {
  if (!timeout) {
    timeout = intent === Intent.WARNING || intent === Intent.DANGER ? DEFAULT_TIMEOUT_ERROR : DEFAULT_TIMEOUT_INFO;
  }

  AppToaster.show({
    message,
    intent,
    icon,
    timeout,
  });
};

export const showToast = (message: string, intent: Intent = Intent.NONE, icon?: IconName, timeout?: number) => {
  displayToast(message, intent, icon, timeout);
};
