
      console.debug("Setup window.env");
      if (import.meta.env) {
        window.env = {
          GOOGLE_TAG_ID: import.meta.env.VITE_GOOGLE_TAG_ID,
          BIRDEATSBUG_APP_ID: import.meta.env.VITE_BIRDEATSBUG_APP_ID,
          GOVCLOUD_MODE: import.meta.env.VITE_GOVCLOUD_MODE,
        };
      } else {
        console.warn("import.meta.env is not defined");
        window.env = {};
      }
      console.debug("GOVCLOUD_MODE: ", window.env.GOVCLOUD_MODE);
      if (window.env.GOOGLE_TAG_ID) {
        console.debug("GOOGLE_TAG_ID: provided");
      }
      if (window.env.BIRDEATSBUG_APP_ID) {
        console.debug("BIRDEATSBUG_APP_ID: provided");
      }
    