import { useState } from "react";
import { Button, Dialog, DialogBody, InputGroup, Label, TextArea } from "@blueprintjs/core";
import { observer } from "mobx-react";

import appStore, { AuthStatus } from "src/store/AppStore";

import { placeholdersByDialogType, supportEmail } from "./constants";
import { EFeedbackType } from "./types";

import "src/lib/BirdEatsBug/BirdEatsBug.scss";
import "./FeedbackDialog.scss";

export const FeedbackDialog = () => {
  const isLoggedIn = appStore.authStatus === AuthStatus.LoggedIn;
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState<string>(appStore.userModel?.email || "");
  const [description, setDescription] = useState("");

  const renderEmailInput = () => {
    if (isLoggedIn) {
      return null;
    }

    return (
      <Label>
        Add an email
        <InputGroup placeholder="Enter here" large value={email} onChange={e => setEmail(e.target.value)} />
      </Label>
    );
  };

  const getSubmitDisabled = () => {
    const missingContent = !description;

    if (isLoggedIn) {
      return missingContent;
    }

    return missingContent || !email;
  };

  const handleClose = () => appStore.feedback.hide();

  const handleUploadSession = () => {
    appStore.feedback.uploadSessionData(email, title, description);
    setDescription("");
    setTitle("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
      if (!getSubmitDisabled()) {
        handleUploadSession();
        event.preventDefault();
      }
    }
  };

  const placeholders = placeholdersByDialogType[appStore.feedback.collectionId as EFeedbackType];

  return (
    <div className="feedback-dialog" onKeyDown={handleKeyDown}>
      <Dialog className="feedback-dialog--wrap" isOpen={appStore.feedback.visible} onClose={handleClose} title="Send feedback">
        <DialogBody>
          <div className="feedback-dialog--tabs">
            <Button
              onClick={() => appStore.feedback.setCollectionId(EFeedbackType.PROBLEM)}
              minimal
              active={appStore.feedback.collectionId === EFeedbackType.PROBLEM}
              text="Problem"
            />
            <Button
              onClick={() => appStore.feedback.setCollectionId(EFeedbackType.QUESTION)}
              minimal
              active={appStore.feedback.collectionId === EFeedbackType.QUESTION}
              text="Question"
            />
            <Button
              onClick={() => appStore.feedback.setCollectionId(EFeedbackType.FEEDBACK_AND_REQUEST)}
              minimal
              active={appStore.feedback.collectionId === EFeedbackType.FEEDBACK_AND_REQUEST}
              text="Feedback & Request"
            />
          </div>
          {renderEmailInput()}
          <Label>
            <p className="feedback-dialog--label">
              <span>Add a title</span>
              <span>Optional</span>
            </p>
            <InputGroup
              placeholder={placeholders.title}
              large
              onChange={e => setTitle(e.target.value)}
              value={title}
              onKeyDown={handleKeyDown}
            />
          </Label>
          <Label>
            Add a description
            <TextArea
              rows={5}
              className="feedback-dialog--description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              fill
              placeholder={placeholders.description}
              onKeyDown={handleKeyDown}
            />
          </Label>
          <div className="feedback-dialog--footer">
            <Button loading={appStore.feedback.loading} onClick={handleUploadSession} disabled={getSubmitDisabled()} large intent="primary">
              Submit
            </Button>
          </div>
          <div>
            You can always email us at{" "}
            <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
              {supportEmail}
            </a>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default observer(FeedbackDialog);
