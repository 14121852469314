import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";

import FeedbackDialog from "src/components/FeedbackDialog";
import LoginHeader from "src/components/LoginHeader";

import AppWrapper from "../AppWrapper";

import "./AppLayout/AppLayout.scss";

/** Main function. */
function AuthAppLayout() {
  return (
    <AppWrapper>
      <FeedbackDialog />
      <div id="app" tabIndex={0} className="app-layout">
        <LoginHeader />
        <Outlet />
      </div>
    </AppWrapper>
  );
}

/** Exports. */
export default observer(AuthAppLayout);
