export const copyToClipboard = async (value: string | object): Promise<void> => {
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }
  // Use the new Clipboard API when available
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(value);
  } else {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = value;
    document.body.appendChild(tempTextArea);
    tempTextArea.focus();
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  }
};
